<template>
  <BCard class="pb-0">
    <BRow class="gap-[8px]">
      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Nama role <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Nama Role"
            rules="required|max:30"
          >
            <BFormInput
              :value="name"
              type="text"
              placeholder="Masukkan Nama Role"
              :state="errors.length > 0 ? false : null"
              @input="$emit('update:name', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Menu Yang Bisa Digunakan <span class="text-danger">{{ $route.params.id ? '' : '*' }}</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Menu Yang Bisa Digunakan"
          >
            <BInputGroup class="input-group-merge d-flex">
              <div
                class="contain-form d-flex flex-col w-100 border rounded-[13px]"
              >
                <div class="d-flex justify-between w-100 px-[39px] py-[24px] border-b">
                  <span class="d-flex font-bold">Semua Akses</span>
                  <BFormCheckbox
                    v-model="selectAll"
                    @change="checkAll($event)"
                  />
                </div>
                <div class="custom-scroll overflow-y-auto h-[calc(100vh_-_450px)]">
                  <div
                    v-for="(menu, i) in menus"
                    :key="i"
                  >
                    <div
                      v-b-toggle="`menu-${i}`"
                      class="d-flex w-100 items-center px-[39px] py-[24px]"
                    >
                      <span
                        class="k-arrow-down-1"
                        :class="menu.sub_menu.length > 0 ? '' : 'text-zinc-300'"
                      />
                      <span class="d-flex ml-[12px] font-bold">{{ menu.name }}</span>
                      <BFormCheckbox
                        v-model="menuSelected"
                        :value="menu.id"
                        class="ml-auto"
                        :disabled="menu.sub_menu.length > 0"
                        @input="updateMenuSelected()"
                      />
                    </div>
                    <b-collapse
                      v-if="menu.sub_menu.length > 0"
                      :id="`menu-${i}`"
                      visible
                    >
                      <div
                        v-for="(child, idx) in menu.sub_menu"
                        :key="idx + 'a'"
                        class="d-flex w-100 items-center px-[39px] py-[24px]"
                      >
                        <span class="border-l h-[20px] w-[15px]" />
                        <span class="d-flex ml-[12px]">{{ child.name }}</span>
                        <BFormCheckbox
                          v-model="menuSelected"
                          :value="child.id"
                          class="ml-auto"
                          @input="updateMenuSelected()"
                        />
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </BInputGroup>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { ValidationProvider } from 'vee-validate'
import {
  max,
} from '@validations'

export default {
  components: {
    ValidationProvider,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      max,
      showPassword: false,
      showConfirmPassword: false,
      alertError,
      menus: [],
      allMenuExist: [],
      menuSelected: [],
      selectAll: false,
    }
  },
  watch: {
    selected(newVal) {
      this.menuSelected = newVal
      this.selectAll = newVal.length === this.allMenuExist.length
    },
  },
  beforeMount() {
    this.getListData()
  },
  methods: {
    async getListData() {
      const url = 'v1/menu_access'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.menus = data

          data.forEach(e => {
            this.allMenuExist.push(e.id)
            if (e.sub_menu.length) {
              e.sub_menu.forEach(el => this.allMenuExist.push(el.id))
            }
          })
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    updateMenuSelected() {
      this.$emit('update:menuSelected', this.menuSelected)
    },
    checkAll(event) {
      if (event) {
        this.menuSelected = this.allMenuExist
        this.$emit('update:menuSelected', this.menuSelected)
      } else {
        this.menuSelected = []
        this.$emit('update:menuSelected', this.menuSelected)
      }
    },
  },
}
</script>
